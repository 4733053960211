import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';

// NOTE!
// This component uses global css, as the content comes from Wordpress
// and has to be parsed into headings, paragraphs, etc. dynamically
// everything wrapped inside .blog-container is affected by css
import './blog.css';

import Layout from '../components/layout';
import Seo from '../components/seo';
import {
    AppointmentButton,
    ContactButton,
    PrevNextLink,
} from '../components/buttons';
import { PageText } from '../components/typography';
import { Trans } from 'gatsby-plugin-react-i18next';

function BlogPostTemplate({ data: { previous, next, post } }) {
    const featuredImage = {
        data: post.frontmatter.cover_image
            ? getImage(post.frontmatter.cover_image)
            : null,
        alt: post.featuredImage?.node?.alt || ``,
    };

    return (
        <Layout
            bgImage={{
                file: 'book',
                opacity: 0.15,
            }}
            headerContent={
                <div className="max-w-2xl mx-auto py-2 px-5 md:px-0">
                    <PrevNextLink path={'/blog/'}>
                        <Trans i18nKey="back">Zurück zum Blog</Trans>
                    </PrevNextLink>
                </div>
            }
        >
            <Seo title={post.frontmatter.title} description={post.excerpt} />
            <div className="bg-light-grey">
                <div className="max-w-2xl mx-auto md:pt-12 pb-16">
                    <article
                        className="blog-post bg-clean-white"
                        itemScope
                        itemType="http://schema.org/Article"
                    >
                        <header>
                            <div className="p-6">
                                <h1
                                    itemProp="headline"
                                    className="text-2xl font-bold text-primary"
                                >
                                    {parse(post.frontmatter.title)}
                                </h1>
                                <PageText type="dimmed">
                                    {parse(post.frontmatter.date)}
                                </PageText>
                            </div>
                            {featuredImage?.data && (
                                <GatsbyImage
                                    image={featuredImage.data}
                                    alt={featuredImage.alt}
                                    className="aspect-video overflow-hidden"
                                    objectFit="cover"
                                    objectPosition="50% 50%"
                                    width={300}
                                />
                            )}
                        </header>
                        {post.html && (
                            <section
                                itemProp="articleBody"
                                className="p-6 border-b-2 border-primary"
                            >
                                {parse(post.html)}
                            </section>
                        )}
                    </article>

                    <nav className="blog-post-nav mt-4 px-2 md:px-0">
                        <ul
                            className="flex flex-wrap justify-between list-none p-0 m-0"
                            style={{ listStyleType: 'none', margin: 0 }}
                        >
                            <li>
                                {previous && (
                                    <PrevNextLink
                                        path={previous.fields.slug}
                                        isNext={false}
                                    >
                                        {parse(previous.frontmatter.title)}
                                    </PrevNextLink>
                                )}
                            </li>
                            <li>
                                {next && (
                                    <PrevNextLink
                                        path={next.fields.slug}
                                        isNext
                                    >
                                        {parse(next.frontmatter.title)}
                                    </PrevNextLink>
                                )}
                            </li>
                        </ul>
                    </nav>

                    <footer className="mt-8">
                        <div className="px-4 text-center max-w-xs mx-auto">
                            <Trans i18nKey="footer_question">
                                Wollen Sie mehr erfahren? Kontaktieren Sie uns
                                gerne:
                            </Trans>
                        </div>
                        <div className="px-4 pt-2 flex flex-col gap-3 items-center">
                            <ContactButton requestDemo={false} isFullWidth />
                            <p className="text-xs">
                                <Trans i18nKey="footer_separator">
                                    — oder direkt im Kalender —
                                </Trans>
                            </p>
                            <AppointmentButton />
                        </div>
                    </footer>
                </div>
            </div>
        </Layout>
    );
}

export const pageQuery = graphql`
    query BlogPostById(
        $id: String!
        $previousPostId: String
        $nextPostId: String
        $language: String
    ) {
        locales: allLocale(
            filter: { ns: { in: ["blog"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        post: markdownRemark(id: { eq: $id }) {
            id
            excerpt
            html
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                cover_image {
                    childImageSharp {
                        gatsbyImageData(width: 1500)
                    }
                }
            }
        }
        previous: markdownRemark(id: { eq: $previousPostId }) {
            frontmatter {
                title
            }
            fields {
                slug
            }
        }
        next: markdownRemark(id: { eq: $nextPostId }) {
            frontmatter {
                title
            }
            fields {
                slug
            }
        }
    }
`;

export default BlogPostTemplate;
